<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="assets/images/slide1.jpg" alt="First slide">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Host Mining Community Development Initiative</h3>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <!--<img class="d-block w-100" src="https://mdbootstrap.com/img/Photos/Slides/img%20(1).jpg" alt="Second slide">-->
        <img class="d-block w-100" src="assets/images/slide1.jpg" alt="Second slide">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Host Mining Community Development Initiative</h3>
      </div>
    </mdb-carousel-item>
    <mdb-carousel-item>
      <div class="view w-100">
        <img class="d-block w-100" src="assets/images/slide1.jpg" alt="Third slide">
        <div class="mask rgba-black-light waves-light" mdbWavesEffect></div>
      </div>
      <div class="carousel-caption">
        <h3 class="h3-responsive">Host Mining Community Development Initiative</h3>
      </div>
    </mdb-carousel-item>
  </mdb-carousel>