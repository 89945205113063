import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { RouteService } from 'src/app/shared/route.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @ViewChild('mobileMenu', {static:false}) mobileMenu: ElementRef;
  @ViewChild('header', {static:false}) header: ElementRef;

  activeLink : string = 'home';

  constructor(private route : RouteService) { }

  ngOnInit(): void {
    this.route.activeLink.subscribe((link) => {
      this.activeLink = link
    })
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    // do some stuff here when the window is scrolled
    const verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 100){
      this.header.nativeElement.classList.add("scrolled");
    }else{
      this.header.nativeElement.classList.remove("scrolled");
    }
  }
    
  scroll(to){
    this.route.route(to)
    this.activeLink = to
  }

  openNav(){
    this.mobileMenu.nativeElement.classList.add('toggle');
  }
  
  closeNav(){
    this.mobileMenu.nativeElement.classList.remove('toggle');
  }

}
