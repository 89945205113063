<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>OUR FOUNDERS</h3>
            <h5 class="mt-5">Samuel Olatunde Sobayo</h5>
            <h5 class="mt-4">Hannatu Indo Muhammad</h5>
            <h5 class="mt-4">Maryam Olayeni Temitayo</h5>
            <h5 class="mt-4">Rita Youngsuk Lagan</h5>
        </div>
    </div>
</div>