import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  services: object = [
    {
      image: 'assets/images/rect1.png',
      title: '	Mediate for sustainable development of mining communities'
    },
    {
      image: 'assets/images/rect2.png',
      title: 'Develop host mining community capacities for inclusion through socio economic programs and projects'
    },
    {
      image: 'assets/images/rect3.png',
      title: 'Development of balanced Community Development Agreements'
    },
    {
      image: 'assets/images/rect3.png',
      title: 'Monitoring and evaluation of existing CDA'
    },
    {
      image: 'assets/images/rect5.png',
      title: 'Advocate for the peaceful development of mining communities'
    },
    {
      image: 'assets/images/rect6.png',
      title: 'Research for development focused on Host Mining Communities with reference to developmental needs with biases on social, environmental and economic principles and themes.'
    },
    {
      image: 'assets/images/rect7.png',
      title: 'Management of Mining operations'
    },
    {
      image: 'assets/images/rect8.png',
      title: 'Consultation services'
    },
    {
      image: 'assets/images/rect9.png',
      title: 'Knowledge of mining activities'
    },
    {
      image: 'assets/images/rect4.png',
      title: 'Recruitment and  relevant skills deveopment for the mining sector'
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
