import { Component, OnInit } from '@angular/core';
import { RouteService } from 'src/app/shared/route.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  activeLink : string = 'home';

  constructor(private route : RouteService) { }

  ngOnInit(): void {
    this.route.activeLink.subscribe((link) => {
      this.activeLink = link
    })
  }

  scroll(to){
    this.route.route(to)
    this.activeLink = to
  }

}
