<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>RECENT DEVELOPMENT & IMPACT</h3>
        </div>
    </div>
    <div class="row mt-5">
        <div class=" column col-12 col-md-6">
            
            <div class="image">
                <img src="assets/images/development.png" alt="image" class="w-100">
            </div>
            
        </div>
        <div class=" column col-12 col-md-6">
            <p>Sustainability Management of Mining Operations
            with focus on Sustainable Host Mining community
            development plans and agreements on behalf of
            Mining Company Transparently</p>

            <p>Research and Production of Annual Reports on
            Mining Company/Mining Community Relationships</p>

            <p>
                Report on Social License Index of Host Mining Countries
            </p>

            <p>Global acceptance for the development of host mining
            communities.</p>

            <p>
                High patronage and recognition across the world
            </p>

            <p>
                Build confidence in the hearts of people for our operation
            </p>
        </div>
    </div>
</div>