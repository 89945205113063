<div class="container">
    <div class="row">
        <div class="col-12 mb-5 col-sm-6 col-md-3">
            <div class="image text-center">
                <img src="assets/images/logo.jpg" alt="">
            </div>
        </div>
        <div class="col-12 mb-5 col-sm-6 col-md-3">
            <h5>Company</h5>
            <div class="link">

                <a 
                [ngClass]="{'active': activeLink == 'about'? true:false}" 
                (click)="scroll('about')"
                >About Us</a>

                <a 
                [ngClass]="{'active': activeLink == 'solutions'? true:false}" 
                (click)="scroll('solutions')"
                >Our Solutions</a>

                <a 
                [ngClass]="{'active': activeLink == 'values'? true:false}" 
                (click)="scroll('values')"
                >Our Values</a>

                <a 
                [ngClass]="{'active': activeLink == 'services'? true:false}" 
                (click)="scroll('services')"
                >Our Services</a>
                
            </div>
        </div>
        <div class="col-12 mb-5 col-sm-6 col-md-3">
            <h5>Subscribe to newsletter</h5>
            <input type="text" placeholder="Enter your email address">
            <button class="subscribe-btn mt-3">Subscribe</button>
        </div>
        <div class="col-12 mb-5 col-sm-6 col-md-3">
            <h5>Stay Connected</h5>
            <div class="social">
                <a href="#"><img src="assets/images/google.png" alt=""></a>
                <a href="#"><img src="assets/images/instagram.png" alt=""></a>
                <a href="#"><img src="assets/images/facebook.png" alt=""></a>
            </div>
        </div>
    </div>
</div>