<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>OUR SERVICES</h3>
        </div>
    </div>
    <div class="row mt-5">
       <div *ngFor="let service of services" class="col-12 col-sm-6 col-md-4">
           <div class="box">
               <img [src]="service.image" alt="">
               <div class="title">
                   <h6>{{service.title}}</h6>
               </div>
           </div>
       </div>
    </div>

</div>