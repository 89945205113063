<div #home class="header">
  <app-header></app-header>
</div>

<div class="carousel">
  <app-carousel></app-carousel>
</div>

<div #solutions class="story bg-gray spacer">
  <app-story></app-story>
</div>

<div #about class="about spacer">
  <app-about></app-about>
</div>

<div #values class="values-solution spacer bg-gray">
  <app-values-solution></app-values-solution>
</div>

<div #services class="services spacer bg-gray">
  <app-services></app-services>
</div>

<div #impact class="development spacer bg-gray">
  <app-development></app-development>
</div>

<div #founders class="founders spacer bg-gray">
  <app-founders></app-founders>
</div>

<div #partners class="partners spacer">
  <app-partners></app-partners>
</div>

<div #contact class="contact spacer bg-gray">
  <app-contact></app-contact>
</div>

<footer class="footer spacer-footer">
  <app-footer></app-footer>
</footer>