<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>ABOUT US</h3>
        </div>
    </div>
    <div class="row mt-5">
        <div class=" column col-12 col-md-6">
            
            <div class="image">
                <img src="assets/images/logo.jpg" alt="image" class="w-100">
            </div>
            
        </div>
        <div class=" column col-12 col-md-6">
            <p>Host Mining Community Development Initiative is a
            non-governmental organisation.</p>
           
           <p>We started operation in 2019 officially after 4 years of research
           into the mining sector to determine the root cause of crisis
           with reference to conflicts and failed projects.</p>
           
           <p>Our goal is to be globally accepted when it comes to
           the development of Host mining communities
           through all inclusive mining project development
           and implementation.</p>
           
            
            <p>HMCDI is self-sustained through personal funds and
            non-profit community engagement and other mining
            sector bias services.</p>

            <p>We operate a self-funded volunteer structure, if you like
            what we do, you can create a team and sustain the national
            project in your location.</p>

            <p>
                We run a self-sustainable system.
            </p>

        </div>
    </div>
</div>