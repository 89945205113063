<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>OUR VALUES</h3>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12">
            <div class="values">
                <div class="value text-center">
                    <div class="circle">
                        <img src="assets/images/star.png" alt="">
                    </div>
                    <div class="title">
                        <h6>Uniqueness in Operation</h6>
                    </div>
                </div>
                <div class="value text-center">
                    <div class="circle">
                        <img src="assets/images/trust.png" alt="">
                    </div>
                    <div class="title">
                        <h6>Transparency</h6>
                    </div>
                </div>
                <div class="value text-center">
                    <div class="circle">
                        <img src="assets/images/strength.png" alt="">
                    </div>
                    <div class="title">
                        <h6>Self-sustainability</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col-12 text-center mt-5">
            <h3>OUR SOLUTION</h3>
        </div>
    </div>
    <div class="row mt-5">
        <div class=" column col-12 col-md-6">
            
            <p>
                Most leaders and citizens of the world at the community level are unaware of 
                natural resources governance  that guarantees sustainable development.
            </p>

            <p>
                The education of  leaders and citizens of communities and their host countries 
                dependent on natural resources  such as Nigeria ,Ghana, Venezuela Mali etc. on the 
                basic knowledge of natural resources governance for sustainable development without 
                conflicts.
            </p>


        </div>
        <div class=" column col-12 col-md-6">
            <div class="image">
                <img src="assets/images/image2.png" alt="image" class="w-100">
            </div>
        </div>
    </div>
</div>