<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>CONTACT US</h3>
            <h6 class="mt-3">Leave a Message for us and we will get back to you shortly</h6>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-7">
            <form class="mt-5">
                <div class="form">
                    <input type="text" placeholder="Name">
                </div>
                <div class="form">
                    <input type="text" placeholder="Email Address">
                </div>
                <div class="form">
                    <input type="text" placeholder="Message">
                </div>
                <div class="form">
                    <button>Send Message</button>
                </div>
            </form>
        </div>
        <div class="col-12 col-md-5 text-center">
            <div class="address">
                <p>
                    <i class="fa fa-envelope mr-3"></i> info@hmcdi.org.ng
                </p>
                <p>
                    <i class="fa fa-phone-square mr-3"></i> +234 813 840 7353 
                </p>
            </div>
        </div>
    </div>
</div>