<div class="container">
    <!--<div class="row">
        <div class="col-12 text-center">
            <h3>OUR VALUES</h3>
        </div>
    </div>-->
    <div class="row">
        <div class=" column col-12 col-md-6">
            
            <p>
                The Mining Industry in Nigeria accounts for 0.3% of its GDP.
            </p>

            <p>The domestic mining industry is underdeveloped, making
            Nigeria to import minerals that can be produced locally.</p>

            <p>
                Rights to ownership is held by the Federal Government,
                They give Mineral Titles to successful applicants to 
                explore for minerals and there after mine.
            </p>

            <p>The problems facing the industry are infrastructure,
            Finance amongst others.</p>

        </div>
        <div class=" column col-12 col-md-6">
            <div class="image">
                <img src="assets/images/image1.png" alt="image" class="w-100">
            </div>
        </div>
    </div>
</div>