<header #header>

    <nav class="main">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="items">
                        <div class="brand">
                            <img (click)="scroll('home')" src="assets/images/logo.jpg" alt="logo">
                        </div>
                        <div class="links">

                            <a 
                            [ngClass]="{'active': activeLink == 'about'? true:false}" 
                            (click)="scroll('about')"
                            >about us</a>

                            <a 
                            [ngClass]="{'active': activeLink == 'services'? true:false}" 
                            (click)="scroll('services')"
                            >services</a>

                            <a 
                            [ngClass]="{'active': activeLink == 'impact'? true:false}" 
                            (click)="scroll('impact')"
                            >impact</a>

                            <a 
                            [ngClass]="{'active': activeLink == 'founders'? true:false}" 
                            (click)="scroll('founders')"
                            >founders</a>

                            <a 
                            [ngClass]="{'active': activeLink == 'partners'? true:false}" 
                            (click)="scroll('partners')"
                            >partners</a>

                            <a 
                            [ngClass]="{'active': activeLink == 'contact'? true:false}" 
                            (click)="scroll('contact')"
                            >contact</a>

                        </div>
                        <div class="toggler">
                            <i class="fas fa-bars" (click)="openNav()"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <!--menu toggle nav-->
    <nav class="mobile" #mobileMenu>
        <div class="header text-center">
            <i class="far fa-times-circle" (click)="closeNav()"></i>
        </div>
        <div class="body">
            <div class="links">

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'about'? true:false}" 
                (click)="scroll('about')"
                >about us</a>

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'services'? true:false}" 
                (click)="scroll('services')"
                >services</a>

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'impact'? true:false}" 
                (click)="scroll('impact')"
                >impact</a>

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'founders'? true:false}" 
                (click)="scroll('founders')"
                >founders</a>

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'partners'? true:false}" 
                (click)="scroll('partners')"
                >partners</a>

                <a 
                (click)="closeNav()"
                [ngClass]="{'active': activeLink == 'contact'? true:false}" 
                (click)="scroll('contact')"
                >contact</a>

            </div>
        </div>
    </nav>

</header>