<div class="container">
    <div class="row">
        <div class="col-12 text-center">
            <h3>PARTNERSHIP CONSIDERATIONS</h3>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="partners">
                <div class="partner">
                    <img src="assets/images/partner2.png" alt="">
                </div>
                <div class="partner">
                    <img src="assets/images/partner1.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>