
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { StoryComponent } from './components/story/story.component';
import { AboutComponent } from './components/about/about.component';
import { ValuesSolutionComponent } from './components/values-solution/values-solution.component';
import { ServicesComponent } from './components/services/services.component';
import { DevelopmentComponent } from './components/development/development.component';
import { FoundersComponent } from './components/founders/founders.component';
import { PartnersComponent } from './components/partners/partners.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CarouselComponent,
    StoryComponent,
    AboutComponent,
    ValuesSolutionComponent,
    ServicesComponent,
    DevelopmentComponent,
    FoundersComponent,
    PartnersComponent,
    ContactComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
