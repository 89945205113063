import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { RouteService } from './shared/route.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild ('home', {static:false}) home: ElementRef;
  @ViewChild ('about', {static:false}) about: ElementRef;
  @ViewChild ('services', {static:false}) services: ElementRef;
  @ViewChild ('impact', {static:false}) impact: ElementRef;
  @ViewChild ('founders', {static:false}) founders: ElementRef;
  @ViewChild ('partners', {static:false}) partners: ElementRef;
  @ViewChild ('contact', {static:false}) contact: ElementRef;
  @ViewChild ('values', {static:false}) values: ElementRef;
  @ViewChild ('solutions', {static:false}) solutions: ElementRef;

  constructor(private route: RouteService){}

  ngOnInit() {
    this.route.routeTo.subscribe((section) => {
      if(section === 'home'){
        this.home.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'about'){
        this.about.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'services'){
        this.services.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'impact'){
        this.impact.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'founders'){
        this.founders.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'partners'){
        this.partners.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'contact'){
        this.contact.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'values'){
        this.values.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
      if(section === 'solutions'){
        this.solutions.nativeElement.scrollIntoView({behavior: 'smooth',});
      }
    })
  }

  @HostListener('window:scroll', [ ]) intersect(){
    let homebounding = this.home.nativeElement.getBoundingClientRect()
    let aboutbounding = this.about.nativeElement.getBoundingClientRect()
    let servicesbounding = this.services.nativeElement.getBoundingClientRect()
    let impactbounding = this.impact.nativeElement.getBoundingClientRect()
    let foundersbounding = this.founders.nativeElement.getBoundingClientRect()
    let partnersbounding = this.partners.nativeElement.getBoundingClientRect()
    let contactbounding = this.contact.nativeElement.getBoundingClientRect()
    let valuesbounding = this.values.nativeElement.getBoundingClientRect()
    let solutionsbounding = this.solutions.nativeElement.getBoundingClientRect()
    //console.log(bounding)
    if (homebounding.y < 40 && homebounding.y > -40) {
      this.route.changeActiveLink('home')
    }
    if (aboutbounding.y < 40 && aboutbounding.y > -40) {
      this.route.changeActiveLink('about')
    }
    if (servicesbounding.y < 40 && servicesbounding.y > -40) {
      this.route.changeActiveLink('services')
    }
    if (impactbounding.y < 40 && impactbounding.y > -40) {
      this.route.changeActiveLink('impact')
    }
    if (foundersbounding.y < 40 && foundersbounding.y > -40) {
      this.route.changeActiveLink('founders')
    }
    if (partnersbounding.y < 40 && partnersbounding.y > -40) {
      this.route.changeActiveLink('partners')
    }
    if (contactbounding.y < 40 && contactbounding.y > -40) {
      this.route.changeActiveLink('contact')
    }
    if (valuesbounding.y < 40 && valuesbounding.y > -40) {
      this.route.changeActiveLink('values')
    }
    if (solutionsbounding.y < 40 && solutionsbounding.y > -40) {
      this.route.changeActiveLink('solutions')
    }
  }
}
